body {
  -webkit-font-smoothing: antialiased;
  font-size: rem-calc(14);
  font-weight: 400;

  p {
    font-size: inherit;
    font-weight: 400;
  }

}

strong {
  font-weight: 700;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
}

h2 {
  letter-spacing: 0.15em;
}

.footer-navigation {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  letter-spacing: 0.15em;
}
