$green: #597c69;
$white: #ffffff;
$black: #000;
$error-background: transparentize($black, 0.2);

$footer-height: rem-calc(60);
$default-padding: rem-calc(30);

@mixin block($fg, $bg) {

  background: $bg;
  color: $fg;

  padding-top: $default-padding * 2;
  padding-bottom: $default-padding * 2;

  h1, h2, h3, h4, h5, h5, h6 {
    color: inherit;
    margin: 0;
    padding: 0;
  }

  hr {
    width: rem-calc(80);
    height: rem-calc(5);
    margin: rem-calc(20) auto rem-calc(26) auto;
    padding: 0;
    background: $fg;
    border: none;
    max-width: 100%;
  }

  a {
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }

}
