.logo {

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;

  @include breakpoint(large) {
    max-width: rem-calc(426);
  }

}

.main {

  background: url('../images/bg_main.jpg') no-repeat center center;
  background-size: cover;

}

.logo-footer {
  position: absolute;
  top: -$footer-height/2;
  width: rem-calc(190px);
  left: 50%;
  transform: translateX(-50%);
}

.green {
  @include block($white, $green);
}

.white {
  @include block($green, $white);
}

.contact {

  text-align: center;

  p {
    margin: 0;
  }

  form {

    margin-top: rem-calc(32);

    label {

      display: block;
      position: relative;
      margin-bottom: $default-padding/2;

      @include breakpoint(medium) {
        margin-bottom: 0;
        &.contact-form-name {
          padding-bottom: $default-padding/2;
        }
      }

      span {
        display: none;

        &.error-message {

          display: block;
          position: absolute;
          top: -44px;
          left: 4px;
          background: $error-background;
          color: $white;
          padding: 8px;

          &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            position: absolute;
            border-top: 12px solid $error-background;
            bottom: -12px;
          }

        }

      }
    }

    input[type=text],
    input[type=email],
    textarea,
    button {
      font-family: 'Roboto Condensed', sans-serif;
      background: transparent;
      border: 1px solid $white;
      color: $white;
      outline: none;
      letter-spacing: 0.15em;
      font-size: 90%;

      width: 100%;
      height: 2rem;
      padding: $default-padding/4;

      &::-webkit-input-placeholder {
        color: $white;
        text-transform: uppercase;
        font-family: 'Roboto Condensed', sans-serif;
      }
    }

    textarea {
      min-height: rem-calc(95);
      resize: vertical;
    }

    button {

      margin: $default-padding/2 0;
      padding: rem-calc(10) rem-calc(64);
      text-transform: uppercase;
      width: auto;

      &:hover {
        background: #fff;
        color: $green;
      }
    }

  }

  .meeting-you {

    margin-top: $default-padding;
    margin-bottom: $default-padding;

    @include breakpoint(large) {
      margin-top: $default-padding*2;
    }

  }

  .site-by {

    margin-top: $default-padding;
    padding-bottom: $default-padding;

    @include breakpoint(large) {
      margin-top: $default-padding*2;
    }

  }

}
