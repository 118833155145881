* {
  outline: none;
}

.full-height {
  min-height: 100vh;
  position: relative;
}

.vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.large-footer-navigation {
  position: fixed;
  height: $footer-height;
  background: $white;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  nav {

    margin-top: rem-calc(20);
    font-size: rem-calc(14);
    text-transform: uppercase;

    a {

      color: $green;
      letter-spacing: 0.15em;
      font-weight: 700;

      &:hover {
        border-bottom: 3px solid $green;
      }

    }

  }

}

.toggle-touch-navigation {

  $burger-width: 70px;

  position: fixed;
  height: $burger-width/2;
  background: url('../images/menu.svg') no-repeat 0 0;
  background-size: cover;
  width: $burger-width;
  bottom: 0;
  left: 50%;
  right: 0;
  z-index: 9999;
  text-align: center;
  transform: translate(-50%, 0);

}

.touch-footer-navigation {

  display: none;
  min-height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  padding: 32px;
  width: 100%;
  text-align: center;

  &.open {
    display: block;
  }

  .close {
    display: inline-block;
    text-indent: -9999em;
    width: 40px;
    height: 40px;
    background: url('../images/cross.svg');
  }

  nav {

    max-width: 320px;
    margin: 0 auto;

    a {
      color: inherit;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {

        font-family: 'Roboto Condensed', sans-serif;
        text-transform: uppercase;
        font-weight: bold;
        padding: 16px 0;
        margin: 16px 0;
        border-bottom: 2px solid $green;
        color: $green;

        a {
          display: inline-block;
          width: 100%;
        }

      }

    }

  }

}

.team-content {

  text-align: center;

  @include breakpoint(medium) {
    padding-left: $default-padding;
    padding-right: $default-padding;
  }

  hr {
    width: rem-calc(80);
    height: rem-calc(5);
    margin: rem-calc(20) auto rem-calc(26) auto;
    border: none;
    background: #ddd;
  }

  a {
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }

}

.gallery-container {

  padding-left: 0 !important;
  min-height: 0;

  .gallery-category {

    position: relative;

    div {
      display: block;
      background: transparentize($green, 0.1);
      color: #fff;
      text-align: center;
      border-radius: 50%;

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      height: 80px;
      width: 80px;
      line-height: 100%;

      h4 {
        color: #fff;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 100%;
        font-size: 12px;
        ///text-transform: uppercase;

        small {
          display: block;
          color: inherit;
          font-size: 8px;
          margin-top: 8px;
        }

      }

      @include breakpoint(medium only) {
        height: 160px;
        width: 160px;
      }

      @include breakpoint(large) {

        height: 160px;
        width: 160px;

        &.smaller {
          height: 100px;
          width: 100px;
        }

        h4 {
          font-size: 16px;
          small {
            font-size: 12px;
          }
        }

      }

    }

  }

}

.about-content {
  text-align: center;
}

.gallery-page {
  background: $green;
  color: #fff;
}

.slider {

  width: 100vw;
  height: 100vh;

  .slide {

    position: relative;
    background: no-repeat center center;
    background-size: contain;
    opacity: 0.2;
    width: 100vw;
    height: 100vh;
    transition: opacity 600ms ease-in-out;

    .image-link {
      position: absolute;
      bottom: 60px;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      padding: 16px;
      a {
        display: block;
        width: 100%;
        color: #fff;
      }
    }

  }

  .slick-current {
    .slide {
      opacity: 1;
      outline: none;
    }
  }

  .slick-arrow {

    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 9999;
    height: 100vh;
    width: 10vw;
    background: transparent;

    i {
      position: absolute;
      top: 50%;
      color: #fff;
      transform: translate(0, -50%);
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
    }

    &.slick-prev {
      left: 0;
      i {
        left: 2vw;
      }
    }

    &.slick-next {
      right: 0;
      i {
        right: 2vw;
      }
    }

  }

  .slick-dots {

    position: absolute;
    top: 2vh;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100vw;
    text-align: center;
    margin: 0;
    padding: 0;

    li {

      display: inline-block;
      margin-right: 4px;

      &:last-of-type {
        margin-right: 0;
      }

      button {
        height: 12px;
        width: 12px;
        border: 2px solid #fff;
        border-radius: 50%;
        background: none;
        text-indent: -9999em;
        overflow: hidden;
        padding: 0;
        margin: 0;
        outline: none;
      }

      &.slick-active {
        button {
          background: $white;
        }
      }

    }

  }

}

.admin-gallery {

  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row wrap;

  li {

    display: block;
    list-style: none;
    padding-right: 4px;
    padding-bottom: 4px;
    flex: 0 20%;
    flex-basis: 50%;

    @include breakpoint(medium) {
      flex-basis: 33%;
    }
    @include breakpoint(large) {
      flex: 0 20%;
    }

    a {

      position: relative;
      display: block;
      background-size: cover;
      background-position: center center;
      min-height: 200px;

      div {

        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        text-align: center;
        color: #fff;
        background: rgba(0, 0, 0, 0.9);
        opacity: 0;
        transition: opacity 400ms ease-in-out;
        vertical-align: middle;

        span {
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-weight: bold;
        }
      }

      &:hover {
        div {
          opacity: 1;
        }
      }
    }
  }

}
